<template>
  <div class="container">
    <div class="row">
      <div class="col-md-5 ml-auto">
        <div class="info-area info-horizontal mt-5">
          <div class="icon icon-warning">
            <i class="tim-icons icon-cart"></i>
          </div>
          <div class="description">
            <h3 class="info-title">{{ $t('marketing1Title') }}</h3>
            <p class="description">
              {{ $t('marketing1Content') }}.
            </p>
          </div>
        </div>
        <div class="info-area info-horizontal">
          <div class="icon icon-primary">
            <i class="tim-icons icon-delivery-fast"></i>
          </div>
          <div class="description">
            <h3 class="info-title">{{ $t('marketing2Title') }}</h3>
            <p class="description">
              {{ $t('marketing2Content') }}.
            </p>
          </div>
        </div>
        <div class="info-area info-horizontal">
          <div class="icon icon-info">
            <i class="tim-icons icon-money-coins"></i>
          </div>
          <div class="description">
            <h3 class="info-title">{{ $t('marketing3Title') }}</h3>
            <p class="description">
              {{ $t('marketing3Content') }}.
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-7 mr-auto">
        <form>
          <card class="card-register card-white">
            <template slot="header">
              <img class="card-img" src="img/card-primary.png" alt="Card image"/>
              <h4 class="card-title">{{ $t('Reset') }}</h4>
            </template>

            <div style="margin: 50px;">
                <base-alert :type="type">
                    {{message}}
                </base-alert>
            </div>

            <router-link class="link footer-link" to="/login" v-if="status">
              <base-button native-type="button" type="primary" class="mb-3" size="lg" block>
                {{ $t('login') }}
              </base-button>
            </router-link>
          </card>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { BaseAlert } from 'src/components';

export default {
  components: {
    BaseAlert
  },
  data() {
      return {
        status: false,
        type: 'info',
        message: null
      };
    },
    methods: {
      async check() {
        let isValidForm = await this.$validator.validateAll();
        if (isValidForm) {
          this.$http.put('reset/'+this.$route.query.token)
          .then( response => response.json())
          .then( response => {
            this.type = 'info';
            this.message = 'Done, check your email for the new password';
            this.status = true;
          })
          .catch( response => {
            this.type = 'danger';
            this.message = 'Error, please contact the administrator'
          })
        }
      }
    },
    mounted() {
      this.check();
    }   
};
</script>
<style>
</style>

<template>
  <card>
    <div class="card-header mb-5">
      <h3 class="card-title">{{ $t('System Settings') }}</h3>
    </div>
    <div class="col-2">
        <router-link class="link footer-link" to="/system/settingsEdit">
          <base-button native-type="button" type="primary" class="mb-3" size="lg" block>
            {{ $t('Edit') }}
          </base-button>
        </router-link>
    </div>
    <div class="card-body">
      <div class="typography-line" v-for="(setting, index) in settings" :key="index">
        <h4><span>{{setting.title}}</span>{{ (setting.id == 'smtpPassword')? '**********':(setting.value||"N/A") }}</h4>
      </div>
    </div>
  </card>
</template>
<script>
export default {
  name: 'system-settings',
  data() {
    return {
      settings: []
    };
  },
  methods: {
    getSettings(){
      this.$http.get('settings')
        .then(response => response.json())
        .then(response => {
          this.settings = response;
        })
        .catch(response => {
          if (response.status == 403)  this.$router.push('/logout');
      })
    },

  },
  mounted(){
    this.getSettings();
  }
};
</script>
<style></style>

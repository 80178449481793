<template>
  <div class="row">
    <div class="col-md-12">
      <card>
        <div>
          <form @submit.prevent="submit">
            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Text') }}</label>
              <div class="col-md-9">
                <base-input v-model="announcement.text"> </base-input>
              </div>
            </div>

            <base-button class="mt-3" native-type="submit" type="primary">
              {{ $t('Submit') }}
            </base-button>
            <base-button class="mt-3" type="default" @click="getAnnouncement">
              {{ $t('Reset') }}
            </base-button>
          </form>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import { mapMutations } from 'vuex'
import { mapActions } from 'vuex'

export default {
  components: {
  },
  data() {
    return {
      announcement: {},
    };
  },
  computed:{
    ...mapState([
      'toEdit'
    ]),
    ...mapGetters([
    ]),
  },
  methods: {
    ...mapMutations([
      'setToEdit'
    ]),
    ...mapActions([
    ]),
    getAnnouncement(){
      this.$http.get('announcements/'+this.toEdit)
      .then(response => response.json())
      .then(response => {
        this.announcement = response;
      })
      .catch(response => {
        if (response.status == 403)  this.$router.push('/logout');
      })
    },
    async submit() {
      let isValidForm = await this.$validator.validateAll();
      if (isValidForm) {
        this.$http.put('announcements/'+this.announcement.id, this.announcement)
        .then( response => response.json())
        .then( response => {
          this.notifyVue('Updated', 'success');
          this.$router.go(-1);
          this.getAnnouncement();
        })
        .catch( response => {
          if (response.status == 403)  this.$router.push('/logout');
          let errorMsg = 'No Updates';
          if(Array.isArray(response.body.error)){
            errorMsg = '<b>Validation Errors</b>';
            response.body.error.forEach(element => {
              errorMsg = errorMsg + '<br/>- ' + element;
            });
          }
          this.notifyVue(errorMsg, 'danger');
        })
      }
    },
    notifyVue(message = null, type = 'info', icon = 'tim-icons icon-bell-55', verticalAlign = 'top', horizontalAlign = 'right') {
      this.$notify({
          message,
          timeout: 15000,
          icon,
          horizontalAlign,
          verticalAlign,
          type
      });
    },
  },
  mounted() {
    this.announcement = {};
    this.getAnnouncement();
  }
};
</script>
<style></style>

<template>
  <div class="container">
    <div class="row">
      <div class="col-md-5 ml-auto">
        <div class="info-area info-horizontal mt-5">
          <div class="icon icon-warning">
            <i class="tim-icons icon-cart"></i>
          </div>
          <div class="description">
            <h3 class="info-title">{{ $t('marketing1Title') }}</h3>
            <p class="description">
              {{ $t('marketing1Content') }}.
            </p>
          </div>
        </div>
        <div class="info-area info-horizontal">
          <div class="icon icon-primary">
            <i class="tim-icons icon-delivery-fast"></i>
          </div>
          <div class="description">
            <h3 class="info-title">{{ $t('marketing2Title') }}</h3>
            <p class="description">
              {{ $t('marketing2Content') }}.
            </p>
          </div>
        </div>
        <div class="info-area info-horizontal">
          <div class="icon icon-info">
            <i class="tim-icons icon-money-coins"></i>
          </div>
          <div class="description">
            <h3 class="info-title">{{ $t('marketing3Title') }}</h3>
            <p class="description">
              {{ $t('marketing3Content') }}.
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-7 mr-auto">
        <form @submit.prevent="register">
          <card class="card-register card-white">
            <template slot="header">
              <img class="card-img" src="img/card-primary.png" alt="Card image"/>
              <h4 class="card-title">{{ $t('RegisterTitle') }}</h4>
            </template>

            <span style="color: red;" v-html="errorMessage"></span>
            <base-input
              v-validate="'required|min:5'"
              :error="getError('Username')"
              v-model="model.username"
              name="Username"
              :placeholder="$t('RegisterUsername')"
              addon-left-icon="tim-icons icon-single-02"
            >
            </base-input>

            <base-input
              v-validate="'required|email'"
              :error="getError('email')"
              v-model="model.email"
              name="email"
              :placeholder="$t('RegisterEmail')"
              addon-left-icon="tim-icons icon-email-85"
            >
            </base-input>

            <base-input
              v-validate="'required|min:6'"
              :error="getError('password')"
              v-model="model.password"
              name="password"
              type="password"
              :placeholder="$t('RegisterPass')"
              addon-left-icon="tim-icons icon-lock-circle"
            >
            </base-input>

            <base-input
              v-validate="'required|min:6'"
              :error="getError('Password Confimration')"
              v-model="model.passConf"
              name="passConf"
              type="password"
              :placeholder="$t('RegisterPassConf')"
              addon-left-icon="tim-icons icon-lock-circle"
            >
            </base-input>

            <div slot="footer">
              <base-button native-type="submit" type="primary" class="mb-3" size="lg" block :class="{disabled: registerNo}">
                {{ $t('RegisterNewButton') }}
              </base-button>
              <router-link class="link footer-link" to="/login">
                <base-button native-type="button" type="primary" class="mb-3" size="lg" block>
                  {{ $t('BackLogin') }}
                </base-button>
              </router-link>
            </div>
          </card>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { BaseCheckbox } from 'src/components';

export default {
  components: {
    BaseCheckbox
  },
  data() {
    return {
      model: {
        username: '',
        email: '',
        password: '',
        passConf: ''
      },
      errorMessage: null,
      registerNo: false
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async register() {
      let isValidForm = await this.$validator.validateAll();
      if (isValidForm) {
          this.registerNo = true;
          this.$http.post('users', this.model)
          .then( response => response.json())
          .then( response => {
            this.$router.push('/registered');
          })
          .catch( response => {
            this.errorMessage = '';
            if(Array.isArray(response.body.error)){
              response.body.error.forEach(element => {
                this.errorMessage = this.errorMessage + '<br/>' + element;
              });
            }
            this.registerNo = false;
          });
        }
      }
    }
  };
</script>
<style></style>

<template>
  <div class="content">
    <h3>{{ $t('To Stop Orders') }}</h3>
    <div class="row mt-5">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                :placeholder="$t('Per page')"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <el-table :data="queriedData">
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="$t(column.label)"
              >
              </el-table-column>
              <el-table-column align="right" :label="$t('Actions')">
                <div slot-scope="props">
                  <base-button
                    @click.native="handleDetails(props.$index, props.row)" :title="$t('Details')"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-paper"></i>
                  </base-button>
                  <base-button
                    @click.native="handleEdit(props.$index, props.row)" :title="$t('Edit')"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>
                  <base-button
                    @click.native="handleDelete(props.$index, props.row)" :title="$t('Delete')"
                    class="remove btn-link"
                    type="danger"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t('Showing') }} {{ from + 1 }} {{ $t('to') }} {{ to }} {{ $t('of') }} {{ total }} {{ $t('entries') }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import { mapMutations } from 'vuex'
import { mapActions } from 'vuex'
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';
import swal from 'sweetalert2';

export default {
  name: 'orders-dashboard',
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100 ,200],
        total: 0
      },
      tableColumns: [
        {
          prop: 'product',
          label: 'Product',
          minWidth: 150
        },
        {
          prop: 'user',
          label: 'User',
          minWidth: 100
        },
        {
          prop: 'status',
          label: 'Status',
          minWidth: 75
        },
        {
          prop: 'start',
          label: 'Start',
          minWidth: 75
        },
        {
          prop: 'end',
          label: 'End',
          minWidth: 75
        }
      ],
      tableData: [],
      searchedData: [],
    };
  },
  computed:{
    ...mapState([
    ]),
    ...mapGetters([
    ]),
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  methods: {
    ...mapMutations([
      'setToEdit',
      'setToDetails'
    ]),
    ...mapActions([
    ]),
    notifyVue(message = null, type = 'info', icon = 'tim-icons icon-bell-55', verticalAlign = 'top', horizontalAlign = 'right') {
      this.$notify({
          message,
          timeout: 15000,
          icon,
          horizontalAlign,
          verticalAlign,
          type
      });
    },
    getOrders(){
      this.$http.get('orders?status=7')
        .then(response => response.json())
        .then(response => {
          this.tableData = response;
        })
        .catch(response => {
          if (response.status == 403)  this.$router.push('/logout');
      })
    },
    handleDetails(index, row) {
      index += (this.pagination.perPage * (this.pagination.currentPage-1));
      this.setToDetails(this.tableData[index].id);
      this.$router.push('/orders/details')
    },
    handleEdit(index, row) {
      index += (this.pagination.perPage * (this.pagination.currentPage-1));
      this.setToEdit(this.tableData[index].id);
      this.$router.push('/orders/edit')
    },
    handleDelete(index, row) {
      index += (this.pagination.perPage * (this.pagination.currentPage-1));
      swal({
        title: this.$t('areYouSure'),
        text: this.$t('canNotRevert'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-danger btn-fill',
        cancelButtonClass: 'btn btn-default btn-fill',
        confirmButtonText: this.$t('yesDel'),
        cancelButtonText: this.$t('Cancel'),
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.$http.delete('orders/'+this.tableData[index].id)
            .then(response => response.json())
            .then(response => {
              let indexToDelete = this.tableData.findIndex(
                tableRow => tableRow.id === row.id
              );
              if (indexToDelete >= 0) {
                this.tableData.splice(indexToDelete, 1);
              }
              swal({
                title: this.$t('Deleted'),
                text: '',
                type: 'success',
                confirmButtonClass: 'btn btn-success btn-fill',
                buttonsStyling: false
              });
            })
            .catch(response => {
              if (response.status == 403)  this.$router.push('/logout');
          })
        }
      });
    }
  },
  mounted() {
    this.getOrders();
  }
};
</script>
<style>
.pagination-select {
  width: 200px;
}
</style>

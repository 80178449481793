<template>
  <div class="row">
    <div class="col-md-12">
      <card>
        <div>
          <form @submit.prevent="submit">
            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Username') }}</label>
              <div class="col-md-9">
                <base-input v-model="user.username"> </base-input>
              </div>
            </div>

            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Status') }}</label>
              <div class="col-md-9">
                <base-radio name="1" v-model="user.status" inline>{{ $t('New') }}</base-radio>
                <base-radio name="2" v-model="user.status" inline>{{ $t('Active') }}</base-radio>
                <base-radio name="3" v-model="user.status" inline>{{ $t('Stopped') }}</base-radio>
              </div>
            </div>

            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Type') }}</label>
              <div class="col-md-9">
                <base-radio name="1" v-model="user.groups_id" inline>{{ $t('Administrator') }}</base-radio>
                <base-radio name="2" v-model="user.groups_id" inline>{{ $t('Staff') }}</base-radio>
                <base-radio name="3" v-model="user.groups_id" inline>{{ $t('Client') }}</base-radio>
                <base-radio name="4" v-model="user.groups_id" inline>{{ $t('Reseller') }}</base-radio>
              </div>
            </div>

            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Email') }}</label>
              <div class="col-md-9">
                <base-input type="email" v-model="user.email"> </base-input>
              </div>
            </div>

            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Password') }}</label>
              <div class="col-md-9">
                <base-input type="password" v-model="user.password"> </base-input>
              </div>
            </div>

            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Password Confirmation') }}</label>
              <div class="col-md-9">
                <base-input type="password" v-model="user.passConf"> </base-input>
              </div>
            </div>

            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('First Name') }}</label>
              <div class="col-md-9">
                <base-input v-model="user.firstName"> </base-input>
              </div>
            </div>

            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Last Name') }}</label>
              <div class="col-md-9">
                <base-input v-model="user.lastName"> </base-input>
              </div>
            </div>

            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('About') }}</label>
              <div class="col-md-9">
                <base-input v-model="user.about"> </base-input>
              </div>
            </div>

            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Organization') }}</label>
              <div class="col-md-9">
                <base-input v-model="user.organization"> </base-input>
              </div>
            </div>

            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Phone') }}</label>
              <div class="col-md-9">
                <base-input v-model="user.phone"> </base-input>
              </div>
            </div>

            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Fax') }}</label>
              <div class="col-md-9">
                <base-input v-model="user.fax"> </base-input>
              </div>
            </div>

            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Address Line 1') }}</label>
              <div class="col-md-9">
                <base-input v-model="user.address1"> </base-input>
              </div>
            </div>

            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Address Line 2') }}</label>
              <div class="col-md-9">
                <base-input v-model="user.address2"> </base-input>
              </div>
            </div>

            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('City') }}</label>
              <div class="col-md-9">
                <base-input v-model="user.city"> </base-input>
              </div>
            </div>

            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('State') }}</label>
              <div class="col-md-9">
                <base-input v-model="user.state"> </base-input>
              </div>
            </div>

            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Zip') }}</label>
              <div class="col-md-9">
                <base-input v-model="user.zip"> </base-input>
              </div>
            </div>

            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Country') }}</label>
              <div class="col-md-9">
                <el-select
                  class="select-primary"
                  v-model="user.country"
                >
                  <el-option
                    v-for="option in getResources.countries"
                    class="select-primary"
                    :value="option.id"
                    :label="option.name"
                    :key="option.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <base-button class="mt-3" native-type="submit" type="primary">
              {{ $t('Submit') }}
            </base-button>
            <base-button class="mt-3" type="default" @click="reset">
              {{ $t('Reset') }}
            </base-button>
          </form>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import { mapMutations } from 'vuex'
import { mapActions } from 'vuex'
import { BaseCheckbox, BaseRadio } from 'src/components/index';
import { TimeSelect, DatePicker, Select, Option } from 'element-ui';

export default {
  components: {
    BaseCheckbox,
    BaseRadio,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  data() {
    return {
      user: {},
    };
  },
  computed:{
    ...mapState([
    ]),
    ...mapGetters([
      'getResources'
    ]),
  },
  methods: {
    ...mapMutations([
    ]),
    ...mapActions([
    ]),
    reset(){
      this.user = {
        'status': 1,
        'groups_id': 3,
      };
    },
    async submit() {
      let isValidForm = await this.$validator.validateAll();
      if (isValidForm) {
        this.$http.post('users/', this.user)
        .then( response => response.json())
        .then( response => {
          this.notifyVue('Created', 'success');
          this.reset();
          this.$router.go(-1);
        })
        .catch( response => {
          if (response.status == 403)  this.$router.push('/logout');
          let errorMsg = 'Not Added';
          if(Array.isArray(response.body.error)){
            errorMsg = '<b>Validation Errors</b>';
            response.body.error.forEach(element => {
              errorMsg = errorMsg + '<br/>- ' + element;
            });
          }
          this.notifyVue(errorMsg, 'danger');
        })
      }
    },
    notifyVue(message = null, type = 'info', icon = 'tim-icons icon-bell-55', verticalAlign = 'top', horizontalAlign = 'right') {
      this.$notify({
          message,
          timeout: 15000,
          icon,
          horizontalAlign,
          verticalAlign,
          type
      });
    },
  },
  mounted() {
    this.reset();
  }
};
</script>
<style></style>

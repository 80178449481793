<template>
  <div class="content">
    <h3>{{ $t('Search') }}</h3>
    <div class="row mt-5">
      <div class="col-12">
        <card>
          <div>
            <form @submit.prevent="submit">
              <div class="row">
                <label class="col-md-3 col-form-label">{{ $t('Search For') }}</label>
                <div class="col-md-9">
                  <base-input v-model="$store.state.toSearch"> </base-input>
                </div>
              </div>
              <base-button class="mt-3" native-type="submit" type="primary">
                {{ $t('Submit') }}
              </base-button>
              <base-button class="mt-3" type="default" @click="$store.state.toSearch = null">
                {{ $t('Reset') }}
              </base-button>
            </form>
          </div>
        </card>
        <card card-body-classes="table-full-width" v-if="productsData.length > 0">
          <h4 slot="header" class="card-title">{{ $t('Products') }}</h4>
          <el-table :data="productsData">
            <el-table-column :max-width="100" align="left" :label="$t('Image')">
              <div slot-scope="props">
                <img v-if="props.row.image" :src="'products/'+props.row.image" :alt="$t('Product image')" />
                <img v-else src="img/image_placeholder.jpg" :alt="$t('Product image')" />
              </div>
            </el-table-column>
            <el-table-column
              min-width="150"
              sortable
              :label="$t('Name')"
              property="name"
            ></el-table-column>
            <el-table-column
              min-width="80"
              sortable
              :label="$t('Type')"
              property="type"
            ></el-table-column>
            <el-table-column
              min-width="80"
              sortable
              :label="$t('Status')"
              property="status"
            ></el-table-column>
            <el-table-column
              min-width="80"
              sortable
              :label="$t('Package')"
              property="package"
            ></el-table-column>
            <el-table-column
              min-width="75"
              sortable
              align="right"
              header-align="right"
              :label="$t('Sell')"
              property="sell"
            ></el-table-column>
            <el-table-column
              min-width="75"
              sortable
              align="right"
              header-align="right"
              :label="$t('Buy')"
              property="buy"
            ></el-table-column>
            <el-table-column align="right" :label="$t('Actions')">
              <div slot-scope="props">
                <base-button
                  @click.native="handleEditProduct(props.$index, props.row)" :title="$t('Edit')"
                  class="edit btn-link"
                  type="info"
                  size="sm"
                  icon
                >
                  <i class="tim-icons icon-pencil"></i>
                </base-button>
                <base-button
                  @click.native="handleDuplicateProduct(props.$index, props.row)" :title="$t('Duplicate')"
                  class="edit btn-link"
                  type="info"
                  size="sm"
                  icon
                >
                  <i class="tim-icons icon-single-copy-04"></i>
                </base-button>
                <base-button
                  @click.native="handleDeleteProduct(props.$index, props.row)" :title="$t('Delete')"
                  class="remove btn-link"
                  type="danger"
                  size="sm"
                  icon
                >
                  <i class="tim-icons icon-simple-remove"></i>
                </base-button>
              </div>
            </el-table-column>
          </el-table>
        </card>
        <card card-body-classes="table-full-width" v-if="usersData.length > 0">
          <h4 slot="header" class="card-title">{{ $t('Users') }}</h4>
          <el-table :data="usersData">
            <el-table-column :max-width="100" align="left" :label="$t('Image')">
              <div slot-scope="props">
                <img v-if="props.row.image" :src="'users/'+props.row.image" :alt="$t('User image')" />
                <img v-else src="img/image_placeholder.jpg" :alt="$t('Product image')" />
              </div>
            </el-table-column>
            <el-table-column
              min-width="100"
              sortable
              :label="$t('Username')"
              property="username"
            ></el-table-column>
            <el-table-column
              min-width="150"
              sortable
              :label="$t('Name')"
              property="fullName"
            ></el-table-column>
            <el-table-column
              min-width="100"
              sortable
              :label="$t('Type')"
              property="type"
            ></el-table-column>
            <el-table-column
              min-width="80"
              sortable
              :label="$t('Status')"
              property="status"
            ></el-table-column>
            <el-table-column
              min-width="200"
              sortable
              :label="$t('Email')"
              property="email"
            ></el-table-column>
            <el-table-column min-width="85" align="right" :label="$t('Actions')">
              <div slot-scope="props">
                <base-button
                  @click.native="handleOrdersUser(props.$index, props.row.id)" :title="$t('Orders')"
                  class="edit btn-link"
                  type="info"
                  size="sm"
                  icon
                >
                  <i class="tim-icons icon-calendar-60"></i>
                </base-button>
                <base-button
                  @click.native="handleEditUser(props.$index, props.row)" :title="$t('Edit')"
                  class="edit btn-link"
                  type="info"
                  size="sm"
                  icon
                >
                  <i class="tim-icons icon-pencil"></i>
                </base-button>
                <base-button
                  @click.native="handleDeleteUser(props.$index, props.row)" :title="$t('Delete')"
                  class="remove btn-link"
                  type="danger"
                  size="sm"
                  icon
                >
                  <i class="tim-icons icon-simple-remove"></i>
                </base-button>
              </div>
            </el-table-column>
          </el-table>
        </card>
        <card card-body-classes="table-full-width" v-if="accountingData.length > 0">
          <h4 slot="header" class="card-title">{{ $t('Accounting') }}</h4>
          <el-table :data="accountingData">
            <el-table-column
              min-width="80"
              sortable
              :label="$t('Time')"
              property="at"
            ></el-table-column>
            <el-table-column
              min-width="75"
              sortable
              align="right"
              header-align="right"
              :label="$t('Income')"
              property="income"
            ></el-table-column>
            <el-table-column
              min-width="75"
              sortable
              align="right"
              header-align="right"
              :label="$t('Expenses')"
              property="expenses"
            ></el-table-column>
            <el-table-column
              min-width="80"
              sortable
              :label="$t('Type')"
              property="type"
            ></el-table-column>
            <el-table-column
              min-width="350"
              sortable
              :label="$t('Description')"
              property="description"
            ></el-table-column>
          </el-table>
        </card>
        <card card-body-classes="table-full-width" v-if="couponsData.length > 0">
          <h4 slot="header" class="card-title">{{ $t('Coupons') }}</h4>
          <el-table :data="couponsData">
            <el-table-column
              min-width="100"
              sortable
              :label="$t('Name')"
              property="name"
            ></el-table-column>
            <el-table-column
              min-width="80"
              sortable
              :label="$t('Status')"
              property="status"
            ></el-table-column>
            <el-table-column
              min-width="80"
              sortable
              :label="$t('Percent')"
              property="percent"
            ></el-table-column>
            <el-table-column
              min-width="80"
              sortable
              :label="$t('Start')"
              property="start"
            ></el-table-column>
            <el-table-column
              min-width="80"
              sortable
              :label="$t('End')"
              property="end"
            ></el-table-column>
            <el-table-column
              min-width="80"
              sortable
              :label="$t('Area')"
              property="area"
            ></el-table-column>
            <el-table-column
              min-width="150"
              sortable
              :label="$t('Product')"
              property="product"
            ></el-table-column>
            <el-table-column align="right" :label="$t('Actions')">
              <div slot-scope="props">
                <base-button
                  @click.native="handleEditCoupon(props.$index, props.row)" :title="$t('Edit')"
                  class="edit btn-link"
                  type="info"
                  size="sm"
                  icon
                >
                  <i class="tim-icons icon-pencil"></i>
                </base-button>
                <base-button
                  @click.native="handleDeleteCoupon(props.$index, props.row)" :title="$t('Delete')"
                  class="remove btn-link"
                  type="danger"
                  size="sm"
                  icon
                >
                  <i class="tim-icons icon-simple-remove"></i>
                </base-button>
              </div>
            </el-table-column>
          </el-table>
        </card>
        <card card-body-classes="table-full-width" v-if="announcementsData.length > 0">
          <h4 slot="header" class="card-title">{{ $t('Announcements') }}</h4>
          <el-table :data="announcementsData">
            <el-table-column
              min-width="80"
              sortable
              :label="$t('Time')"
              property="at"
            ></el-table-column>
            <el-table-column
              min-width="350"
              sortable
              :label="$t('Text')"
              property="text"
            ></el-table-column>
            <el-table-column align="right" :label="$t('Actions')">
              <div slot-scope="props">
                <base-button
                  @click.native="handleEditAnnouncement(props.$index, props.row)" :title="$t('Edit')"
                  class="edit btn-link"
                  type="info"
                  size="sm"
                  icon
                >
                  <i class="tim-icons icon-pencil"></i>
                </base-button>
                <base-button
                  @click.native="handleDeleteAnnouncement(props.$index, props.row)" :title="$t('Delete')"
                  class="remove btn-link"
                  type="danger"
                  size="sm"
                  icon
                >
                  <i class="tim-icons icon-simple-remove"></i>
                </base-button>
              </div>
            </el-table-column>
          </el-table>
        </card>
        <card card-body-classes="table-full-width" v-if="ordersData.length > 0">
          <h4 slot="header" class="card-title">{{ $t('Orders') }}</h4>
          <el-table :data="ordersData">
            <el-table-column
              min-width="80"
              sortable
              :label="$t('Time')"
              property="at"
            ></el-table-column>
            <el-table-column
              min-width="75"
              sortable
              :label="$t('Name')"
              property="user"
            ></el-table-column>
            <el-table-column
              min-width="100"
              sortable
              :label="$t('Product')"
              property="product"
            ></el-table-column>
            <el-table-column
              min-width="50"
              sortable
              :label="$t('Status')"
              property="status"
            ></el-table-column>
            <el-table-column
              min-width="150"
              sortable
              :label="$t('Details')"
              property="details"
            ></el-table-column>
            <el-table-column align="right" :label="$t('Actions')">
              <div slot-scope="props">
                <base-button
                  @click.native="handleOrdersUser(props.$index, props.row.userId)" :title="$t('Orders')"
                  class="edit btn-link"
                  type="info"
                  size="sm"
                  icon
                >
                  <i class="tim-icons icon-calendar-60"></i>
                </base-button>
                <base-button
                  @click.native="handlePaymentDetails(props.$index, props.row)" :title="$t('Payment Details')"
                  class="edit btn-link"
                  type="info"
                  size="sm"
                  icon
                >
                  <i class="tim-icons icon-coins"></i>
                </base-button>
                <base-button
                  @click.native="handleOrderDetails(props.$index, props.row)" :title="$t('Details')"
                  class="edit btn-link"
                  type="info"
                  size="sm"
                  icon
                >
                  <i class="tim-icons icon-paper"></i>
                </base-button>
                <base-button
                  @click.native="handleOrderEdit(props.$index, props.row)" :title="$t('Edit')"
                  class="edit btn-link"
                  type="info"
                  size="sm"
                  icon
                >
                  <i class="tim-icons icon-pencil"></i>
                </base-button>
              </div>
            </el-table-column>
          </el-table>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import { mapMutations } from 'vuex'
import { mapActions } from 'vuex'
import { Table, TableColumn } from 'element-ui';
import swal from 'sweetalert2';

export default {
  name: 'system-users',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      search: null,
      productsData: [],
      usersData: [],
      accountingData: [],
      announcementsData: [],
      couponsData: [],
      ordersData: [],
      // fieldsData: [],
      // notificationsData: [],
      // requestsData: [],
    };
  },
  computed:{
    ...mapState([
      'toSearch'
    ]),
    ...mapGetters([
    ]),
  },
  methods: {
    ...mapMutations([
      'setToEdit',
      'setToDuplicate',
      'setToOrders',
      'setToDetails'
    ]),
    ...mapActions([
    ]),
    notifyVue(message = null, type = 'info', icon = 'tim-icons icon-bell-55', verticalAlign = 'top', horizontalAlign = 'right') {
      this.$notify({
          message,
          timeout: 15000,
          icon,
          horizontalAlign,
          verticalAlign,
          type
      });
    },
    async submit() {
      let isValidForm = await this.$validator.validateAll();
      if (isValidForm) {
        this.search = null;
        this.productsData = [];
        this.usersData = [];
        this.accountingData = [];
        this.announcementsData = [];
        this.couponsData = [];
        this.ordersData = [];
        // this.fieldsData = [];
        // this.notificationsData = [];
        // this.requestsData = [];
        this.$http.post('search/', {search: this.$store.state.toSearch})
        .then( response => response.json())
        .then( response => {
          this.productsData = response.products;
          this.usersData = response.users;
          this.accountingData = response.accounting;
          this.announcementsData = response.announcements;
          this.couponsData = response.coupons;
          this.ordersData = response.orders;
          // this.fieldsData = response.fields;
          // this.notificationsData = response.notifications;
          // this.requestsData = response.requests;
        })
        .catch( response => {
          if (response.status == 403)  this.$router.push('/logout');
          let errorMsg = 'No Updates';
          if(Array.isArray(response.body.error)){
            errorMsg = '<b>Validation Errors</b>';
            response.body.error.forEach(element => {
              errorMsg = errorMsg + '<br/>- ' + element;
            });
          }
          this.notifyVue(errorMsg, 'danger');
        })
      }
    },
    handleEditProduct(index, row) {
      this.setToEdit(row.id);
      this.$router.push('/products/edit')
    },
    handleDuplicateProduct(index, row) {
      this.setToDuplicate(row.id);
      this.$router.push('/products/add')
    },
    handleDeleteProduct(index, row) {
      swal({
        title: this.$t('areYouSure'),
        text: this.$t('canNotRevert'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-danger btn-fill',
        cancelButtonClass: 'btn btn-default btn-fill',
        confirmButtonText: this.$t('yesDel'),
        cancelButtonText: this.$t('Cancel'),
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.$http.delete('products/'+row.id)
            .then(response => response.json())
            .then(response => {
              let indexToDelete = this.productsData.findIndex(
                tableRow => tableRow.id === row.id
              );
              if (indexToDelete >= 0) {
                this.productsData.splice(indexToDelete, 1);
              }
              swal({
                title: this.$t('Deleted'),
                text: '',
                type: 'success',
                confirmButtonClass: 'btn btn-success btn-fill',
                buttonsStyling: false
              });
            })
            .catch(response => {
              if (response.status == 403)  this.$router.push('/logout');
          })
        }
      });
    },
    handleOrdersUser(index, row) {
      this.setToOrders(row);
      this.$router.push('/clients/orders')
    },
    handleEditUser(index, row) {
      this.setToEdit(row.id);
      this.$router.push('/system/edit')
    },
    handleDeleteUser(index, row) {
      swal({
        title: this.$t('areYouSure'),
        text: this.$t('canNotRevert'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-danger btn-fill',
        cancelButtonClass: 'btn btn-default btn-fill',
        confirmButtonText: this.$t('yesDel'),
        cancelButtonText: this.$t('Cancel'),
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.$http.delete('users/'+this.usersData[index].id)
            .then(response => response.json())
            .then(response => {
              let indexToDelete = this.usersData.findIndex(
                tableRow => tableRow.id === row.id
              );
              if (indexToDelete >= 0) {
                this.usersData.splice(indexToDelete, 1);
              }
              swal({
                title: this.$t('Deleted'),
                text: '',
                type: 'success',
                confirmButtonClass: 'btn btn-success btn-fill',
                buttonsStyling: false
              });
            })
            .catch(response => {
              if (response.status == 403)  this.$router.push('/logout');
          })
        }
      });
    },
    handleEditAnnouncement(index, row) {
      this.setToEdit(row.id);
      this.$router.push('/system/announcementEdit')
    },
    handleDeleteAnnouncement(index, row) {
      swal({
        title: this.$t('areYouSure'),
        text: this.$t('canNotRevert'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-danger btn-fill',
        cancelButtonClass: 'btn btn-default btn-fill',
        confirmButtonText: this.$t('yesDel'),
        cancelButtonText: this.$t('Cancel'),
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.$http.delete('announcements/'+this.announcementsData[index].id)
            .then(response => response.json())
            .then(response => {
              let indexToDelete = this.announcementsData.findIndex(
                tableRow => tableRow.id === row.id
              );
              if (indexToDelete >= 0) {
                this.announcementsData.splice(indexToDelete, 1);
              }
              swal({
                title: this.$t('Deleted'),
                text: '',
                type: 'success',
                confirmButtonClass: 'btn btn-success btn-fill',
                buttonsStyling: false
              });
            })
            .catch(response => {
              if (response.status == 403)  this.$router.push('/logout');
          })
        }
      });
    },
    handleEditCoupon(index, row) {
      this.setToEdit(row.id);
      this.$router.push('/products/couponEdit')
    },
    handleDeleteCoupon(index, row) {
      swal({
        title: this.$t('areYouSure'),
        text: this.$t('canNotRevert'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-danger btn-fill',
        cancelButtonClass: 'btn btn-default btn-fill',
        confirmButtonText: this.$t('yesDel'),
        cancelButtonText: this.$t('Cancel'),
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.$http.delete('coupons/'+this.couponsData[index].id)
            .then(response => response.json())
            .then(response => {
              let indexToDelete = this.couponsData.findIndex(
                tableRow => tableRow.id === row.id
              );
              if (indexToDelete >= 0) {
                this.couponsData.splice(indexToDelete, 1);
              }
              swal({
                title: this.$t('Deleted'),
                text: '',
                type: 'success',
                confirmButtonClass: 'btn btn-success btn-fill',
                buttonsStyling: false
              });
            })
            .catch(response => {
              if (response.status == 403)  this.$router.push('/logout');
          })
        }
      });
    },
    handlePaymentDetails(index, row) {
      this.setToDetails(row.id);
      this.$router.push('/payments/details')
    },
    handleOrderDetails(index, row) {
      this.setToDetails(row.id);
      this.$router.push('/orders/details')
    },
    handleOrderEdit(index, row) {
      this.setToEdit(row.id);
      this.$router.push('/orders/edit')
    },
  },
  mounted() {
    if(this.$store.state.toSearch) this.submit();
  }
};
</script>
<style>
  .pagination-select {
    width: 200px;
  }
</style>

<template>
  <card class="card-user">
    <p class="card-text"></p>
    <div class="author">
      <div class="block block-one"></div>
      <div class="block block-two"></div>
      <div class="block block-three"></div>
      <div class="block block-four"></div>
      <a href="javascript:void(0)">
        <img v-if="me.image" class="avatar" :src="'users/'+me.image" alt="My Avatar" />
        <img v-else class="avatar" src="img/default-avatar.png" alt="My Avatar" />
        <h5 class="title">{{ me.firstName }} {{ me.lastName }}</h5>
      </a>
      <p class="description">{{ me.organization }}</p>
    </div>
    <p></p>
    <div class="card-description">{{ me.about }}</div>

    <div slot="footer" class="button-container">
    </div>
  </card>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
    };
  },
  computed:{
    ...mapGetters([
      'getResources'
    ]),
    me() {
      return this.getResources.me;
    }
  }
};
</script>
<style></style>

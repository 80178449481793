<template>
  <div class="container">
    <div class="col-lg-4 col-md-6 ml-auto mr-auto">
      <card class="card-lock card-white text-center">
        <img slot="header" src="img/emilyz.jpg" alt="..." />
        <h4 class="card-title">{{ $t('logoutMessage') }}</h4>
      </card>
    </div>
  </div>
</template>
<script>

export default {
  mounted() {
    this.$http.delete('tokens');
    localStorage.removeItem("token");
    this.$router.push('/login');
  }
};
</script>
<style></style>

<template>
  <card>
    <h5 slot="header" class="title">{{ $t('Edit Profile') }}</h5>
    <form @submit.prevent="submit">
      <div class="row">
        <div class="col-md-4">
          <base-input
            type="text"
            :label="$t('Username')"
            :placeholder="$t('Username')"
            disabled=true
            v-model="me.username"
          >
          </base-input>
        </div>
        <div class="col-md-4">
          <base-input
            type="password"
            :label="$t('Password')"
            :placeholder="$t('Password')"
            v-model="me.password"
          >
          </base-input>
        </div>
        <div class="col-md-4">
          <base-input
            type="password"
            :label="$t('Password Confirmation')"
            :placeholder="$t('Password Confirmation')"
            v-model="me.passConf"
          >
          </base-input>
        </div>        
      </div>
      <div class="row">
        <div class="col-md-6">
          <base-input
            type="text"
            :label="$t('Company')"
            :disabled="false"
            :placeholder="$t('Company')"
            v-model="me.organization"
          >
          </base-input>
        </div>
        <div class="col-md-6">
          <base-input
            type="email"
            :label="$t('Email address')"
            :placeholder="$t('Email')"
            v-model="me.email"
          >
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <base-input
            type="text"
            :label="$t('First Name')"
            :placeholder="$t('First Name')"
            v-model="me.firstName"
          >
          </base-input>
        </div>
        <div class="col-md-6">
          <base-input
            type="text"
            :label="$t('Last Name')"
            :placeholder="$t('Last Name')"
            v-model="me.lastName"
          >
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <base-input
            type="text"
            :label="$t('Address Line 1')"
            :placeholder="$t('Address Line 1')"
            v-model="me.address1"
          >
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <base-input
            type="text"
            :label="$t('Address Line 2')"
            :placeholder="$t('Address Line 2')"
            v-model="me.address2"
          >
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <base-input
            :label="$t('Postal Code')"
            :placeholder="$t('ZIP Code')"
            v-model="me.zip"
          >
          </base-input>
        </div>
        <div class="col-md-4">
          <base-input
            type="text"
            :label="$t('City')"
            :placeholder="$t('City')"
            v-model="me.city"
          >
          </base-input>
        </div>
        <div class="col-md-4">
          <label>{{ $t('Country') }}</label>
          <base-input>
            <el-select
              v-model="me.country"
              class="select-primary"
            >
              <el-option
                v-for="option in getResources.countries"
                class="select-primary"
                :value="option.id"
                :label="option.name"
                :key="option.id"
              ></el-option>
            </el-select>
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <base-input
            :label="$t('About Me')"
          >
            <textarea
              class="form-control"
              :placeholder="$t('About Me')"
              v-model="me.about"
            >
            </textarea>
          </base-input>
        </div>
      </div>

      <div class="row" id="myImage">
        <label class="col-md-3 col-form-label">{{ $t('My Image') }}</label>
        <div class="col-md-9">
          <image-upload type="avatar" @change="newImage" select-text="Select Image" />
        </div>
      </div>
      
      <base-button native-type="submit" type="primary" class="btn-fill">
        {{ $t('Save') }}
      </base-button>
      <base-button native-type="button" type="default" class="btn-fill" @click="getMe">
        {{ $t('Reset') }}
      </base-button>
    </form>
  </card>
</template>
<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import { mapMutations } from 'vuex'
import { mapActions } from 'vuex'
import {ImageUpload} from 'src/components/index';
import { TimeSelect, DatePicker, Select, Option } from 'element-ui';

export default {
  components: {
    ImageUpload,
    [Option.name]: Option,
    [Select.name]: Select
  },
  data() {
    return {
      me: {}
    };
  },
  computed:{
    ...mapState([
      'recourse'
    ]),
    ...mapGetters([
      'getResources'
    ]),
  },
  methods: {
    ...mapMutations([
    ]),
    ...mapActions([
    ]),
    async submit() {
      let isValidForm = await this.$validator.validateAll();
      if (isValidForm) {
        this.$http.put('profile', this.me)
        .then( response => response.json())
        .then( response => {
          this.notifyVue('Updated', 'success');
          this.getResources();
          this.getMe();
          this.me.password = null;
          this.me.passConf = null;
          // this.$router.go(-1);
        })
        .catch( response => {
          if (response.status == 403)  this.$router.push('/logout');
          let errorMsg = 'No Updates';
          if(Array.isArray(response.body.error)){
            errorMsg = '<b>Validation Errors</b>';
            response.body.error.forEach(element => {
              errorMsg = errorMsg + '<br/>- ' + element;
            });
          }
          this.notifyVue(errorMsg, 'danger');
        })
      }
    },
    newImage(file){
      let reader = new FileReader();
      let vm = this;
      reader.onloadend = function (e) {
        var b64 = reader.result;
        // var b64 = reader.result.replace(/^data:.+;base64,/, '');
        vm.me.image = b64;
      };

      reader.readAsDataURL(file);
    },
    notifyVue(message = null, type = 'info', icon = 'tim-icons icon-bell-55', verticalAlign = 'top', horizontalAlign = 'right') {
      this.$notify({
          message,
          timeout: 15000,
          icon,
          horizontalAlign,
          verticalAlign,
          type
      });
    },
    getMe(){
      this.$http.get('profile')
        .then(response => response.json())
        .then(response => {
          this.me = response;
          let image = (this.me.image)? 'users/'+this.me.image:'img/default-avatar.png';
          document.getElementById('myImage').getElementsByTagName('img')[0].src = image ;
        })
        .catch(response => {
          if (response.status == 403)  this.$router.push('/logout');
      })
    }
  },
  mounted() {
    this.getMe();
  }
};
</script>
<style></style>

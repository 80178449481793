<template>
  <div class="row">
    <div class="col-md-12">
      <card>
        <div>
          <form @submit.prevent="submit">
            <div class="row" v-for="(setting, index) in settings" :key="index">
              <label class="col-md-3 col-form-label">{{setting.title}}</label>
              <div class="col-md-9">
                <base-input :type="(setting.id == 'smtpPassword')? 'password':'text'" v-model="settings[index].value"> </base-input>
              </div>
            </div>

            <base-button class="mt-3" native-type="submit" type="primary">
              {{ $t('Submit') }}
            </base-button>
            <base-button class="mt-3" type="default" @click="getSettings">
              {{ $t('Reset') }}
            </base-button>
          </form>
        </div>
      </card>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
        settings: []
    };
  },
  methods: {
    getSettings(){
        this.$http.get('settings')
        .then(response => response.json())
        .then(response => {
            this.settings = response;
        })
        .catch(response => {
            if (response.status == 403)  this.$router.push('/logout');
        })
    },
    async submit() {
        let isValidForm = await this.$validator.validateAll();
        if (isValidForm) {
            this.$http.put('settings', this.settings)
            .then( response => response.json())
            .then( response => {
                if(Array.isArray(response)){
                    response.forEach(element => {
                        if(element.status) {
                            this.notifyVue(element.title+' Updated', 'success');
                            this.$router.go(-1);
                        }else{
                            // this.notifyVue(element.title+' Not Updated', 'danger');
                        }
                    });
                }else{
                  this.getSettings();
                }
            })
            .catch( response => {
                if (response.status == 403)  this.$router.push('/logout');
                let errorMsg = 'No Updates';
                if(Array.isArray(response.body.error)){
                    errorMsg = '<b>Validation Errors</b>';
                    response.body.error.forEach(element => {
                    errorMsg = errorMsg + '<br/>- ' + element;
                    });
                }
                this.notifyVue(errorMsg, 'danger');
            })
        }
    },
    notifyVue(message = null, type = 'info', icon = 'tim-icons icon-bell-55', verticalAlign = 'top', horizontalAlign = 'right') {
        this.$notify({
            message,
            timeout: 15000,
            icon,
            horizontalAlign,
            verticalAlign,
            type
        });
    },

  },
  mounted(){
    this.getSettings();
  }
};
</script>
<style></style>

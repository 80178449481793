<template>
  <div class="row">
    <div class="col-md-12" v-if="!product.id">
      <card>
        <div>
          <h4 slot="header" class="card-title">{{ $t('Product Data') }}</h4>
          <form @submit.prevent="submit">
            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Name') }}</label>
              <div class="col-md-9">
                <base-input v-model="product.name"> </base-input>
              </div>
            </div>

            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Status') }}</label>
              <div class="col-md-9">
                <base-radio name="2" v-model="product.status" inline>{{ $t('Active') }}</base-radio>
                <base-radio name="3" v-model="product.status" inline>{{ $t('Stopped') }}</base-radio>
              </div>
            </div>

            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Reorderable') }}</label>
              <div class="col-md-9">
                <base-radio name="yes" v-model="product.reorderable" inline>{{ $t('Yes') }}</base-radio>
                <base-radio name="no" v-model="product.reorderable" inline>{{ $t('No') }}</base-radio>
              </div>
            </div>

            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Clients') }}</label>
              <div class="col-md-9">
                <base-radio name="0" v-model="product.client" inline>{{ $t('Both') }}</base-radio>
                <base-radio name="1" v-model="product.client" inline>{{ $t('Clients Only') }}</base-radio>
                <base-radio name="2" v-model="product.client" inline>{{ $t('Resellers Only') }}</base-radio>
              </div>
            </div>

            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Provider') }}</label>
              <div class="col-md-9">
                <base-input type="text" v-model="product.provider"> </base-input>
              </div>
            </div>

            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Note') }}</label>
              <div class="col-md-9">
                <div class="form-group">
                  <div class="mb-0">
                    <textarea class="form-control" style="margin-button: 10px;" id="note" cols="30" rows="10" @keyup="noteUpdate"></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Buy') }}</label>
              <div class="col-md-9">
                <base-input type="number" min="0" step="0.01" v-model="product.buy"> </base-input>
              </div>
            </div>

            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Sell') }}</label>
              <div class="col-md-9">
                <base-input type="number" min="0" step="0.01" v-model="product.sell"> </base-input>
              </div>
            </div>
            
            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Help URL') }}</label>
              <div class="col-md-9">
                <base-input v-model="product.helper"> </base-input>
              </div>
            </div>

            <div class="row" id="productImage">
              <label class="col-md-3 col-form-label">{{ $t('Product Image') }}</label>
              <div class="col-md-9">
                <image-upload @change="newImage" select-text="Select Image" />
              </div>
            </div>            

            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Type') }}</label>
              <div class="col-md-9">
                <el-select
                  class="select-primary"
                  v-model="product.type"
                >
                  <el-option
                    v-for="option in getResources.productTypes"
                    class="select-primary"
                    :value="option.id"
                    :label="option.name"
                    :key="option.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <p></p>
            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Package') }}</label>
              <div class="col-md-9">
                <el-select
                  class="select-primary"
                  v-model="product.package"
                >
                  <el-option
                    v-for="option in getResources.packages"
                    class="select-primary"
                    :value="option.id"
                    :label="option.name"
                    :key="option.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <base-button class="mt-3" native-type="submit" type="primary">
              {{ $t('Submit') }}
            </base-button>
            <base-button class="mt-3" type="default" @click="getProduct">
              {{ $t('Reset') }}
            </base-button>
          </form>
        </div>
      </card>
    </div>
    <div class="col-md-12" v-if="product.id">
      <card>
        <div>
          <h4 slot="header" class="card-title">{{ $t('Reseller Prices') }}</h4>
          <div class='el-table__header-wrapper'>
            <table>
              <th>{{ $t('Count') }}</th>
              <th>{{ $t('Sell Price') }}</th>
              <th>
                <base-button class="mt-3" type="primary" @click="addPrice">
                  <i class="tim-icons icon-simple-add"></i>
                </base-button>
              </th>
              <tr v-for="(price, index) in pricesList" :key="index">
                <td class="td2">
                  <base-input type="number" min="0" step="1" v-model="price.count"> </base-input>
                </td>
                <td class="td2">
                  <base-input type="number" min="0" step="0.01" v-model="price.sell"> </base-input>
                </td>
                <td>
                  <base-button class="mt-3" type="danger" @click="delPrice(index)">
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                </td>
              </tr>
            </table>
            <base-button class="mt-3" type="primary" @click="pricesSubmit">
              {{ $t('Submit') }}
            </base-button>
            <base-button class="mt-3" type="default" @click="getPrices">
              {{ $t('Reset') }}
            </base-button>
          </div>
        </div>
      </card>
    </div>
    <div class="col-md-12" v-if="product.id">
      <card>
        <div>
          <h4 slot="header" class="card-title">{{ $t('Order Fields') }}</h4>
          <div class='el-table__header-wrapper'>
            <table>
              <th>{{ $t('Field Name') }}</th>
              <th>{{ $t('Help URL') }}</th>
              <th>
                <base-button class="mt-3" type="primary" @click="addField">
                  <i class="tim-icons icon-simple-add"></i>
                </base-button>
              </th>
              <tr v-for="(field, index) in fieldsList" :key="index">
                <td class="td2">
                  <base-input v-model="field.name"> </base-input>
                </td>
                <td class="td2">
                  <base-input v-model="field.help"> </base-input>
                </td>
                <td>
                  <base-button class="mt-3" type="danger" @click="delField(index)">
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                </td>
              </tr>
            </table>
            <base-button class="mt-3" type="primary" @click="fieldsSubmit">
              {{ $t('Submit') }}
            </base-button>
            <base-button class="mt-3" type="default" @click="getFields">
              {{ $t('Reset') }}
            </base-button>
          </div>
        </div>
      </card>
    </div>
    <div class="col-md-12" v-if="product.id">
      <card>
        <div>
          <base-button
            @click="$router.push('/products/dashboard')"
            native-type="button"
            type="primary"
            size="sm"
            :title="$t('Products')"
            block
            >
            {{ $t('Back to Products') }}
          </base-button>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import { mapMutations } from 'vuex'
import { mapActions } from 'vuex'
import { BaseCheckbox, BaseRadio } from 'src/components/index';
import { TimeSelect, DatePicker, Select, Option } from 'element-ui';
import { ImageUpload } from 'src/components/index';

export default {
  components: {
    BaseCheckbox,
    BaseRadio,
    [Option.name]: Option,
    [Select.name]: Select,
    ImageUpload
  },
  data() {
    return {
      radios: {
        radio1: '1',
        radio2: '2',
        radio3: '2',
        radioOn: '2',
        radioOff: '2'
      },
      checkboxes: {
        first: false,
        second: false,
        a: false,
        b: false,
        c: false,
        unchecked: false,
        checked: true,
        disabledUnchecked: false,
        disabledChecked: true
      },
      product: {},
      pricesList: [],
      fieldsList: [],
    };
  },
  computed:{
    ...mapState([
      'toDuplicate'
    ]),
    ...mapGetters([
      'getResources'
    ]),
  },
  methods: {
    ...mapMutations([
      'setToDuplicate'
    ]),
    ...mapActions([
      'apiResources'
    ]),
    getProduct(duplicated = false){
      this.$http.get('products/'+this.toDuplicate)
      .then(response => response.json())
      .then(response => {
        this.product = response;
        let image = (this.product.image)? 'products/'+this.product.image:'img/image_placeholder.jpg';
        document.getElementById('productImage').getElementsByTagName('img')[0].src = image ;
        document.getElementById('note').value = this.product.note;
        if(duplicated) {
          this.product.id = null;
          this.product.image = null;
          document.getElementById('productImage').getElementsByTagName('img')[0].src = 'img/image_placeholder.jpg' ;
          // this.setToDuplicate(null);
        }
      })
      .catch(response => {
        if (response.status == 403)  this.$router.push('/logout');
      })
    },
    getPrices(){
      this.$http.get('prices/'+this.toDuplicate)
      .then(response => response.json())
      .then(response => {
        this.pricesList = response;
      })
      .catch(response => {
        if (response.status == 403)  this.$router.push('/logout');
      })
    },
    addPrice(){
      this.pricesList.push({
        "count": 0,
        "sell": 0
      });
    },
    delPrice(index){
      this.pricesList.splice(index, 1);
    },
    getFields(){
      this.$http.get('fields/'+this.toDuplicate)
      .then(response => response.json())
      .then(response => {
        this.fieldsList = response;
      })
      .catch(response => {
        if (response.status == 403)  this.$router.push('/logout');
      })
    },
    addField(){
      this.fieldsList.push({
        "name": null
      });
    },
    delField(index){
      this.fieldsList.splice(index, 1);
    },
    noteUpdate(){
      this.product.note = document.getElementById('note').value;
    },
    async submit() {
      let isValidForm = await this.$validator.validateAll();
      if (isValidForm) {
        this.$http.post('products/', this.product)
        .then( response => response.json())
        .then( response => {
          this.notifyVue('Created', 'success');
          this.product = {id: response.id};
          // this.$router.go(-1);
          // this.getProduct();
        })
        .catch( response => {
          if (response.status == 403)  this.$router.push('/logout');
          let errorMsg = 'Not Added';
          if(Array.isArray(response.body.error)){
            errorMsg = '<b>Validation Errors</b>';
            response.body.error.forEach(element => {
              errorMsg = errorMsg + '<br/>- ' + element;
            });
          }
          this.notifyVue(errorMsg, 'danger');
        })
      }
    },
    async pricesSubmit(){
      let isValidForm = await this.$validator.validateAll();
      if (isValidForm) {
        this.$http.post('prices/'+this.product.id, this.pricesList)
        .then( response => response.json())
        .then( response => {
          this.notifyVue('Created', 'success')
          // this.reset();
          // this.pricesReset();
        })
        .catch( response => {
          if (response.status == 403)  this.$router.push('/logout');
          let errorMsg = 'Not Added';
          if(Array.isArray(response.body.error)){
            errorMsg = '<b>Validation Errors</b>';
            response.body.error.forEach(element => {
              errorMsg = errorMsg + '<br/>- ' + element;
            });
          }
          this.notifyVue(errorMsg, 'danger');
        })
      }      
    },
    async fieldsSubmit(){
      let isValidForm = await this.$validator.validateAll();
      if (isValidForm) {
        this.$http.post('fields/'+this.product.id, this.fieldsList)
        .then( response => response.json())
        .then( response => {
          this.notifyVue('Created', 'success')
          // this.reset();
          // this.fieldsReset();
        })
        .catch( response => {
          if (response.status == 403)  this.$router.push('/logout');
          let errorMsg = 'Not Added';
          if(Array.isArray(response.body.error)){
            errorMsg = '<b>Validation Errors</b>';
            response.body.error.forEach(element => {
              errorMsg = errorMsg + '<br/>- ' + element;
            });
          }
          this.notifyVue(errorMsg, 'danger');
        })
      }      
    },
    newImage(file){
      let reader = new FileReader();
      let vm = this;
      reader.onloadend = function (e) {
        var b64 = reader.result;
        // var b64 = reader.result.replace(/^data:.+;base64,/, '');
        vm.product.image = b64;
      };

      reader.readAsDataURL(file);
    },
    notifyVue(message = null, type = 'info', icon = 'tim-icons icon-bell-55', verticalAlign = 'top', horizontalAlign = 'right') {
      this.$notify({
          message,
          timeout: 15000,
          icon,
          horizontalAlign,
          verticalAlign,
          type
      });
    },
  },
  mounted() {
    this.apiResources();
    // this.product = {};
    // this.pricesList = [];
    if(this.toDuplicate){
      this.getProduct(true);
      this.getPrices();
      this.getFields();
    }
  }
};
</script>
<style>
  table{
    width: 90%;
  }
  
  th{
    width: 30%;
    color: white;
  }

  td.td2{
    padding: 5px;
    padding-top: 25px;
  }
</style>

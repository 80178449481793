<template>
  <div>
    <base-alert
      type="info"
      dismissible icon="tim-icons icon-bell-55"
      v-for="(announcement, index) in announcements"
      :key="index"
      >
      {{ announcement.text }}
    </base-alert>
    <base-alert
      v-if="alerts.payments"
      type="warning"
      dismissible icon="tim-icons icon-bell-55"
      >
      {{ $t('alertPayments') }}
    </base-alert>
    <base-alert
      v-if="alerts.orders"
      type="warning"
      dismissible icon="tim-icons icon-bell-55"
      >
      {{ $t('alertOrders') }}
    </base-alert>
    <br>
    <div v-if="myGroup == 'Administrator' || myGroup == 'Staff'">
  <!--    <div class="row"> -->
      <div class="col-12 card">
        <label style="padding-right: 20px;"><h5>{{ $t('History Days') }}</h5></label>
        <el-select
          class="select-primary mb-3 pagination-select"
          v-model="days"
          :placeholder="$t('History Days')"
          >
          <el-option
            class="select-primary"
            v-for="item in daysList"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6 mx-auto" style="margin: 0px" v-for="card in adminStatsCards" :key="card.subTitle">
          <div class="card ">
            <div class="card-body text-center py-5">
              <h4 slot="header" class="card-title">{{ $t(card.title) }}</h4>
              <el-table :data="card.data">
                <el-table-column
                  min-width="150"
                  sortable
                  :label="$t('Name')"
                  property="name"
                ></el-table-column>
                <el-table-column
                  min-width="55"
                  sortable
                  align="right"
                  header-align="right"
                  label=""
                  property="value"
                ></el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- Small charts -->
        <div class="col-lg-4" :class="{ 'text-right': isRTL }">
          <card type="chart" v-if="ordersChart.chartData.totalCount">
            <template slot="header">
              <h5 class="card-category">{{ $t('Orders') }}</h5>
              <h3 class="card-title">
                <i class="tim-icons icon-cart text-info "></i> {{ordersChart.chartData.totalCount}}
              </h3>
            </template>
            <div class="chart-area">
              <bar-chart
                style="height: 100%"
                :chart-data="ordersChart.chartData"
                :gradient-stops="ordersChart.gradientStops"
                :extra-options="ordersChart.extraOptions"
                >
              </bar-chart>
            </div>
          </card>
        </div>
      </div>
    </div>
    <div v-if="myGroup == 'Client' || myGroup == 'Reseller'">
      <div class="row">
        <div class="col-12 sticky">
          <router-link class="link" to="/my/products">
            <base-button native-type="button" type="primary" class="mb-2" size="sm">
              {{ $t('Buy Product') }}
            </base-button>
          </router-link>
          <router-link class="link" to="/my/orders">
            <base-button native-type="button" type="primary" class="mb-2" size="sm">
              {{ $t('My Products') }}
            </base-button>
          </router-link>
        </div>
        <!-- Stats Cards -->
        <div class="col-lg-3 col-md-6" v-for="card in userStatsCards" :key="card.subTitle">
          <stats-card
            :title="card.title"
            :sub-title="$t(card.subTitle)"
            :type="card.type"
            :icon="card.icon"
            >
            <div slot="footer" v-html="card.footer"></div>
          </stats-card>
        </div>
      </div>
      <div class="row" v-if="tableData.length">
        <card card-body-classes="table-full-width">
          <h3>{{ $t('Expire Soon Orders') }}</h3>
          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                :placeholder="$t('Per page')"
                >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <el-table :data="queriedData">
              <el-table-column :max-width="100" align="center" :label="$t('Image')">
                <div slot-scope="props">
                  <img v-if="props.row.image" :src="'products/'+props.row.image" :alt="$t('Product image')" />
                  <img v-else src="img/image_placeholder.jpg" :alt="$t('Product image')" />
                  <div style="margin-top: 5px">
                    <base-button
                      @click="reorder(props.$index, props.row)"
                      native-type="button"
                      type="primary"
                      size="sm"
                      :title="$t('Reorder')"
                      >
                      {{ $t('Reorder') }}
                    </base-button>
                  </div>
                </div>
              </el-table-column>
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="$t(column.label)"
                >
              </el-table-column>
              <el-table-column align="right" :label="$t('Actions')">
                <div slot-scope="props">
                  <base-button
                    @click.native="reorder(props.$index, props.row)" :title="$t('Reorder')"
                    class="remove btn-link"
                    type="success"
                    size="sm"
                    icon
                    >
                    <i class="tim-icons icon-refresh-01"></i>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                {{ $t('Showing') }} {{ from + 1 }} {{ $t('to') }} {{ to }} {{ $t('of') }} {{ total }} {{ $t('entries') }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import LineChart from '@/components/Charts/LineChart';
import BarChart from '@/components/Charts/BarChart';
import * as chartConfigs from '@/components/Charts/config';
import StatsCard from 'src/components/Cards/StatsCard';
import config from '@/config';
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';
import { Modal, BaseAlert } from 'src/components';
import { mapMutations } from 'vuex'

export default {
  components: {
    LineChart,
    BarChart,
    StatsCard,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Modal,
    BaseAlert
  },
  data() {
    return {
      announcements: [],
      myGroup: '',
      days: 1,
      daysList: [1, 2, 3, 7, 15, 20, 30, 60, 90, 180, 365],
      userStatsCards: [],
      adminStatsCards: [],
      ordersChart: {
        extraOptions: chartConfigs.barChartOptions,
        chartData: {
          labels: [],
          totalCount : 0,
          datasets: [
            {
              label: 'Orders',
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: []
            }
          ]
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0]
      },
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100 ,200],
        total: 0
      },
      tableColumns: [
        {
          prop: 'name',
          label: 'Product',
          minWidth: 150
        },
        {
          prop: 'end',
          label: 'End',
          minWidth: 75
        },
        {
          prop: 'note',
          label: 'Note',
          minWidth: 150
        }
      ],
      tableData: [],
      searchedData: [],
      alerts: {payments: false, orders: false}
    };
  },
  computed: {
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
    enableRTL() {
      return this.$route.query.enableRTL;
    },
    isRTL() {
      return this.$rtl.isRTL;
    }
  },
  watch: {
    days(val) {
      this.getDashboard();
    }
  },
  methods: {
    ...mapMutations([
      'setToReorder'
    ]),
    reorder(index, row){
      this.setToReorder(row.id);
      this.$router.push('/my/reorder')
    },
    getAnnouncements(){
      this.$http.get('announcements/')
      .then(response => response.json())
      .then(response => {
        this.announcements = response;
      })
      .catch(response => {
        if (response.status == 403)  this.$router.push('/logout');
      })
    },
    getDashboard(){
      this.$http.get('dashboard/'+this.days)
      .then(response => response.json())
      .then(response => {
        this.tableData = response.expireSoon;
        this.alerts = response.alerts;
        // Update adminStatsCards
          this.adminStatsCards = [
            {
              title: 'Expire Soon Orders',
              data: response.expireSoon
            },
            {
              title: 'Order Status',
              data: response.ordersStatus
            },
            {
              title: 'Top Products',
              data: response.topProduct
            },
            {
              title: 'Top Product Types',
              data: response.topProductType
            },
            {
              title: 'Top Client (Count)',
              data: response.topClientCount
            },
            {
              title: 'Top Client (Sum)',
              data: response.topClientAmount
            },
            {
              title: 'Top Reseller (Count)',
              data: response.topResellerCount
            },
            {
              title: 'Top Reseller (Sum)',
              data: response.topResellerAmount
            },
            {
              title: 'Top Method (Count)',
              data: response.topPaymentMethodCount
            },
            {
              title: 'Top Method (Sum)',
              data: response.topPaymentMethodAmount
            },
            {
              title: 'Active Users',
              data: response.activeUsers
            },
            {
              title: 'Top Location',
              data: response.topLocation
            },
          ];
          this.userStatsCards = [
            {
              title: (response.activeOrders || '0'),
              subTitle: 'Orders',
              type: 'info',
              icon: 'tim-icons icon-cart',
              footer: '<b><i class="tim-icons icon-alert-circle-exc"></i> Active Orders</b>'
            },
            {
              title: (response.needReview || '0'),
              subTitle: 'Reviews',
              type: 'warning',
              icon: 'tim-icons icon-satisfied',
              footer: '<b><i class="tim-icons icon-alert-circle-exc"></i> Orders need to review</b>'
            },
            {
              title: (response.notifications || '0'),
              subTitle: 'Notifications',
              type: 'danger',
              icon: 'tim-icons icon-bell-55',
              footer: '<b><i class="tim-icons icon-alert-circle-exc"></i> New Notifications</b>'
            }
          ];
          
          if(response.expireSoon.length){
            this.userStatsCards.push({
              title: (response.expireSoon.length || '0'),
              subTitle: 'Soon',
              type: 'danger',
              icon: 'tim-icons icon-cart',
              footer: '<b><i class="tim-icons icon-alert-circle-exc"></i> Expire Soon Orders</b>'
            });
          }else{
            this.userStatsCards.push({
              // title: (new Date(response.lastOrder).toLocaleDateString('en-GB') || 'N/A'),
              title: (response.lastOrder || '0'),
              subTitle: 'Last Order',
              type: 'info',
              icon: 'tim-icons icon-cart',
              footer: '<b><i class="tim-icons icon-alert-circle-exc"></i> Last Order Date</b>'
            });
          }
          
        // Update ordersChart
          let labelsArray = [];
          let datasetsArray = [];
          this.ordersChart.chartData.totalCount = 0;
          response.ordersStatus.forEach( element => {
            labelsArray.push(element.name);
            datasetsArray.push(element.count);
            this.ordersChart.chartData.totalCount += parseInt(element.count);
          });
          this.ordersChart.chartData.labels = labelsArray;
          this.ordersChart.chartData.datasets[0].data = datasetsArray;
      })
      .catch(response => {
        if (response.status == 403)  this.$router.push('/logout');
      })
    },
    notifyVue(message = null, type = 'info', icon = 'tim-icons icon-bell-55', verticalAlign = 'top', horizontalAlign = 'right') {
      this.$notify({
          message,
          timeout: 15000,
          icon,
          horizontalAlign,
          verticalAlign,
          type
      });
    }
  },
  mounted() {
    this.getDashboard();
    this.getAnnouncements();
    this.i18n = this.$i18n;
    if (this.enableRTL) {
      this.i18n.locale = 'ar';
      this.$rtl.enableRTL();
    };
    this.$http.get('profile')
    .then(response => response.json())
    .then(response => {
        this.myGroup = response.type;
    });
  },
  beforeDestroy() {
    if (this.$rtl.isRTL) {
      this.i18n.locale = 'en';
      this.$rtl.disableRTL();
    }
  },
};
</script>
<style>
  div.sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    z-index:999;
    margin-bottom: 10px;
  }
</style>

<template>
  <div class="row">
    <div class="col-md-12">
      <card>
        <div>
          <form @submit.prevent="submit">
            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Start') }}</label>
              <div class="col-md-9">
                <base-input type="date" v-model="order.start"> </base-input>
              </div>
            </div>

            <div class="row">
              <label class="col-md-3 col-form-label">End</label>
              <div class="col-md-9">
                <base-input type="date" v-model="order.end"> </base-input>
              </div>
            </div>

            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Buy') }}</label>
              <div class="col-md-9">
                <base-input type="number" min="0" step="0.01" v-model="order.buy"> </base-input>
              </div>
            </div>

            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Sell') }}</label>
              <div class="col-md-9">
                <base-input type="number" min="0" step="0.01" v-model="order.sell"> </base-input>
              </div>
            </div>
            
            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Note') }}</label>
              <div class="col-md-9">
                <base-input v-model="order.notes"> </base-input>
              </div>
            </div>
            
            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Details') }}</label>
              <div class="col-md-9">
                <base-input>
                  <textarea
                    class="form-control"
                    :placeholder="$t('Details')"
                    v-model="order.activeDetails"
                  >
                  </textarea>
                </base-input>
              </div>
            </div>

            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Product') }}</label>
              <div class="col-md-9">
                <el-select
                  class="select-primary"
                  v-model="order.product"
                >
                  <el-option
                    v-for="option in getResources.products"
                    class="select-primary"
                    :value="option.id"
                    :label="option.name"
                    :key="option.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <p></p>
            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Status') }}</label>
              <div class="col-md-9">
                <el-select
                  class="select-primary"
                  v-model="order.status"
                >
                  <el-option
                    v-for="option in getResources.orders"
                    class="select-primary"
                    :value="option.id"
                    :label="option.name"
                    :key="option.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <base-button class="mt-3" native-type="submit" type="primary">
              {{ $t('Submit') }}
            </base-button>
            <base-button class="mt-3" type="default" @click="getOrder">
              {{ $t('Reset') }}
            </base-button>
          </form>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import { mapMutations } from 'vuex'
import { mapActions } from 'vuex'
import { BaseCheckbox, BaseRadio } from 'src/components/index';
import { TimeSelect, DatePicker, Select, Option } from 'element-ui';

export default {
  components: {
    BaseCheckbox,
    BaseRadio,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  data() {
    return {
      radios: {
        radio1: '1',
        radio2: '2',
        radio3: '2',
        radioOn: '2',
        radioOff: '2'
      },
      checkboxes: {
        first: false,
        second: false,
        a: false,
        b: false,
        c: false,
        unchecked: false,
        checked: true,
        disabledUnchecked: false,
        disabledChecked: true
      },
      order: {},
      pricesList: [],
      fieldsList: [],
    };
  },
  computed:{
    ...mapState([
      'toEdit'
    ]),
    ...mapGetters([
      'getResources'
    ]),
  },
  methods: {
    ...mapMutations([
      'setToEdit'
    ]),
    ...mapActions([
      'apiResources'
    ]),
    getOrder(){
      this.$http.get('orders/'+this.toEdit)
      .then(response => response.json())
      .then(response => {
        this.order = response;
      })
      .catch(response => {
        if (response.status == 403)  this.$router.push('/logout');
      })
    },
    async submit() {
      let isValidForm = await this.$validator.validateAll();
      if (isValidForm) {
        this.$http.put('orders/'+this.order.id, this.order)
        .then( response => response.json())
        .then( response => {
          this.notifyVue('Updated', 'success');
          this.$router.go(-1);
          this.getOrder();
        })
        .catch( response => {
          if (response.status == 403)  this.$router.push('/logout');
          let errorMsg = 'No Updates';
          if(Array.isArray(response.body.error)){
            errorMsg = '<b>Validation Errors</b>';
            response.body.error.forEach(element => {
              errorMsg = errorMsg + '<br/>- ' + element;
            });
          }
          this.notifyVue(errorMsg, 'danger');
        })
      }
    },
    notifyVue(message = null, type = 'info', icon = 'tim-icons icon-bell-55', verticalAlign = 'top', horizontalAlign = 'right') {
      this.$notify({
          message,
          timeout: 15000,
          icon,
          horizontalAlign,
          verticalAlign,
          type
      });
    },
  },
  mounted() {
    this.apiResources();
    this.order = {};
    this.pricesList = [];
    this.getOrder();
  }
};
</script>
<style>
  table{
    width: 90%;
  }
  
  th{
    width: 30%;
    color: white;
  }

  td.td2{
    padding: 5px;
    padding-top: 25px;
  }
</style>

<template>
  <div class="row">
    <div class="col-md-12">
      <card>
        <div>
          <form @submit.prevent="submit">
            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Name') }}</label>
              <div class="col-md-9">
                <base-input v-model="coupon.name"> </base-input>
              </div>
            </div>

            <!-- <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Status') }}</label>
              <div class="col-md-9">
                <base-radio name="2" v-model="coupon.status" inline>{{ $t('Active') }}</base-radio>
                <base-radio name="3" v-model="coupon.status" inline>{{ $t('Stopped') }}</base-radio>
              </div>
            </div> -->

            <!-- <div class="row">
              <label class="col-md-3 col-form-label">Limit</label>
              <div class="col-md-9">
                <base-input type="number" min="0" step="1" v-model="coupon.limit"> </base-input>
              </div>
            </div> -->

            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Users Type') }}</label>
              <div class="col-md-9">
                <base-radio name="0" v-model="coupon.user" inline>{{ $t('Both') }}</base-radio>
                <base-radio name="3" v-model="coupon.user" inline>{{ $t('Clients') }}</base-radio>
                <base-radio name="4" v-model="coupon.user" inline>{{ $t('Resellers') }}</base-radio>
              </div>
            </div>

            <!-- <div class="row">
              <label class="col-md-3 col-form-label">Minimum Charge</label>
              <div class="col-md-9">
                <base-input type="number" min="0" step="0.01" v-model="coupon.min"> </base-input>
              </div>
            </div> -->

            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Discount Percent') }}</label>
              <div class="col-md-9">
                <base-input type="number" min="0" step="0.01" v-model="coupon.percent"> </base-input>
              </div>
            </div>

            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('From Date') }}</label>
              <div class="col-md-9">
                <base-input type="date" v-model="coupon.start"> </base-input>
              </div>
            </div>

            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('To Date') }}</label>
              <div class="col-md-9">
                <base-input type="date" v-model="coupon.end"> </base-input>
              </div>
            </div>

            <div class="row">
              <label class="col-md-3 col-form-label">{{ $t('Area') }}</label>
              <div class="col-md-9">
                <base-radio name="1" v-model="coupon.area" inline>{{ $t('Product') }}</base-radio>
                <base-radio name="2" v-model="coupon.area" inline>{{ $t('Total') }}</base-radio>
              </div>
            </div>

            <div class="row" v-if="coupon.area == 2">
              <label class="col-md-3 col-form-label">{{ $t('Multi') }}</label>
              <div class="col-md-9">
                <base-radio name="1" v-model="coupon.multi" inline>{{ $t('Yes') }}</base-radio>
                <base-radio name="2" v-model="coupon.multi" inline>{{ $t('No') }}</base-radio>
              </div>
            </div>

            <div class="row" v-if="coupon.area == 1">
              <label class="col-md-3 col-form-label">{{ $t('Product') }}</label>
              <div class="col-md-9">
                <el-select
                  class="select-primary"
                  v-model="coupon.product"
                >
                  <el-option
                    v-for="option in getResources.products"
                    class="select-primary"
                    :value="option.id"
                    :label="option.name+' '+option.coupon"
                    :key="option.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>

            <base-button class="mt-3" native-type="submit" type="primary">
              {{ $t('Submit') }}
            </base-button>
            <base-button class="mt-3" type="default" @click="getCoupon">
              {{ $t('Reset') }}
            </base-button>
          </form>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import { mapMutations } from 'vuex'
import { mapActions } from 'vuex'
import { BaseCheckbox, BaseRadio } from 'src/components/index';
import { TimeSelect, DatePicker, Select, Option } from 'element-ui';

export default {
  components: {
    BaseCheckbox,
    BaseRadio,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  data() {
    return {
      radios: {
        radio1: '1',
        radio2: '2',
        radio3: '2',
        radioOn: '2',
        radioOff: '2'
      },
      checkboxes: {
        first: false,
        second: false,
        a: false,
        b: false,
        c: false,
        unchecked: false,
        checked: true,
        disabledUnchecked: false,
        disabledChecked: true
      },
      coupon: {},
    };
  },
  computed:{
    ...mapState([
      'toEdit'
    ]),
    ...mapGetters([
      'getResources'
    ]),
  },
  methods: {
    ...mapMutations([
      'setToEdit'
    ]),
    ...mapActions([
    ]),
    getCoupon(){
      this.$http.get('coupons/'+this.toEdit)
      .then(response => response.json())
      .then(response => {
        this.coupon = response;
      })
      .catch(response => {
        if (response.status == 403)  this.$router.push('/logout');
      })
    },
    async submit() {
      let isValidForm = await this.$validator.validateAll();
      if (isValidForm) {
        this.$http.put('coupons/'+this.coupon.id, this.coupon)
        .then( response => response.json())
        .then( response => {
          this.notifyVue('Updated', 'success');
          this.$router.go(-1);
          this.getCoupon();
        })
        .catch( response => {
          if (response.status == 403)  this.$router.push('/logout');
          let errorMsg = 'No Updates';
          if(Array.isArray(response.body.error)){
            errorMsg = '<b>Validation Errors</b>';
            response.body.error.forEach(element => {
              errorMsg = errorMsg + '<br/>- ' + element;
            });
          }
          this.notifyVue(errorMsg, 'danger');
        })
      }
    },
    notifyVue(message = null, type = 'info', icon = 'tim-icons icon-bell-55', verticalAlign = 'top', horizontalAlign = 'right') {
      this.$notify({
          message,
          timeout: 15000,
          icon,
          horizontalAlign,
          verticalAlign,
          type
      });
    },
  },
  mounted() {
    this.coupon = {};
    this.getCoupon();
  }
};
</script>
<style></style>

<template>
  <div class="container">
    <div class="col-lg-4 col-md-6 ml-auto mr-auto">
      <form @submit.prevent="submit" v-if="login">
        <card class="card-login card-white">
          <template slot="header">
            <img src="img/card-primary.png" alt="" />
            <h1 class="card-title">{{ $t('LoginTitle') }}</h1>
          </template>

          <div>
            <span style="color: red;" v-html="errorMessage"></span>
            <base-input
              name="username"
              v-validate="'required|min:5'"
              :error="getError('username')"
              v-model="model.username"
              :placeholder="$t('UsernameLogin')"
              addon-left-icon="tim-icons icon-single-02"
            >
            </base-input>

            <base-input
              name="password"
              v-validate="'required|min:6'"
              :error="getError('password')"
              v-model="model.password"
              type="password"
              :placeholder="$t('PasswordLogin')"
              addon-left-icon="tim-icons icon-lock-circle"
            >
            </base-input>
          </div>

          <div style="text-align: center">
            <p><a href="#" @click.prevent="loginSwitch">{{ $t('ResetPassword') }}</a></p>
          </div>

          <div slot="footer">
            <base-button native-type="submit" type="primary" class="mb-3" size="lg" block>
              {{ $t('LoginButton') }}
            </base-button>
            <router-link class="link footer-link" to="/register">
              <base-button native-type="button" type="primary" class="mb-3" size="lg" block>
                {{ $t('RegisterButton') }}
              </base-button>
            </router-link>
          </div>
        </card>
      </form>
      <form @submit.prevent="reset" v-if="!login">
        <card class="card-login card-white">
          <template slot="header">
            <img src="img/card-primary.png" alt="" />
            <h1 class="card-title">{{ $t('ResetTitle') }}</h1>
          </template>

          <div>
            <span style="color: red;" v-html="errorMessage"></span>
            <base-input
              name="username"
              v-validate="'required|min:5'"
              :error="getError('username')"
              v-model="model.username"
              :placeholder="$t('UsernameReset')"
              addon-left-icon="tim-icons icon-single-02"
            >
            </base-input>

            <base-input
              name="email"
              v-validate="'required|min:4'"
              :error="getError('email')"
              v-model="model.email"
              type="email"
              :placeholder="$t('EmailReset')"
              addon-left-icon="tim-icons icon-email-85"
            >
            </base-input>
          </div>

          <div style="text-align: center">
            <p><a href="#" @click.prevent="loginSwitch">{{ $t('ResetLogin') }}</a></p>
          </div>

          <div slot="footer">
            <base-button native-type="submit" type="primary" class="mb-3" size="lg" block>
              {{ $t('ResetReset') }}
            </base-button>
          </div>
        </card>
      </form>
    </div>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import { mapGetters } from 'vuex'
  import { mapMutations } from 'vuex'
  import { mapActions } from 'vuex'

  export default {
    data() {
      return {
        model: {
          username: '',
          password: '',
          email: ''
        },
        errorMessage: '',
        login: true
      };
    },
    methods: {
      ...mapActions([
        'apiResources'
      ]),
      getError(fieldName) {
        return this.errors.first(fieldName);
      },
      async submit() {
        let isValidForm = await this.$validator.validateAll();
        if (isValidForm) {
          this.$http.post('tokens', this.model)
          .then( response => response.json())
          .then( response => {
            localStorage.setItem("token",response.token);
            this.apiResources();
            this.$router.push('/');
          })
          .catch( response => {
            if(response.status == 403) this.errorMessage = 'Wrong username or password';
          })
        }
      },
      loginSwitch(){
        this.login = !this.login;
        this.errorMessage = '';
      },
      reset(){
        this.errorMessage = '';
        this.$http.post('reset', this.model)
        .then( response => response.json())
        .then( response => {
          this.errorMessage = response.message;
          // this.login = true;
        }).catch( response => {
          if(Array.isArray(response.body.error)){
            response.body.error.forEach(element => {
              this.errorMessage = this.errorMessage + ', ' + element;
            });
          }
        })
      }
    },
    mounted() {
      localStorage.removeItem("token");
      this.apiResources();
    }
  };
</script>
<style>
  .navbar-nav .nav-item p {
    line-height: inherit;
    margin-left: 5px;
  }
</style>

<template>
  <div class="content">
    <h3>{{ $t('Order Details') }}</h3>
    <div class="row mt-5">
      <div class="col-12">
        <card v-if="order.activeDetails">
          <h4>{{ $t('Activation details') }}</h4>
          <dir>
            <h4><pre>{{order.activeDetails}}</pre></h4>
          </dir>
        </card>
      </div>
      <div class="col-12">
        <card>
          <div class="card-body">
            <div class="typography-line"><h4><span>{{ $t('Added') }}</span>{{order.at}}</h4></div>
            <div v-if="order.user" class="typography-line"><h4><span>{{ $t('User') }}</span>{{order.user}}</h4></div>
            <div class="typography-line"><h4><span>{{ $t('Product') }}</span>{{order.product}}</h4></div>
            <div class="typography-line"><h4><span>{{ $t('Package') }}</span>{{order.package}}</h4></div>
            <div class="typography-line"><h4><span>{{ $t('Type') }}</span>{{order.type}}</h4></div>
            <div v-if="order.sell" class="typography-line"><h4><span>{{ $t('Buy -> Sell') }}</span>{{order.buy || 'N/A'}} -> {{order.sell || 'N/A'}}</h4></div>
            <div class="typography-line"><h4><span>{{ $t('Start -> End') }}</span>{{order.start || 'N/A'}} -> {{order.end || 'N/A'}}</h4></div>
            <div v-if="order.payment" class="typography-line"><h4><span>{{ $t('Payment Reference') }}</span>{{order.payment || 'N/A'}}</h4></div>
            <div class="typography-line"><h4><span>{{ $t('Note') }}</span>{{order.cnote}}</h4></div>
            <div v-if="order.notes" class="typography-line"><h4><span>{{ $t('Admin Note') }}</span>{{order.notes}}</h4></div>
          </div>
        </card>
      </div>
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4>{{ $t('Details') }}</h4>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                :placeholder="$t('Per page')"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <el-table :data="queriedData">
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="$t(column.label)"
              >
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t('Showing') }} {{ from + 1 }} {{ $t('to') }} {{ to }} {{ $t('of') }} {{ total }} {{ $t('entries') }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import { mapMutations } from 'vuex'
import { mapActions } from 'vuex'
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';
import swal from 'sweetalert2';

export default {
  name: 'orders-details',
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100 ,200],
        total: 0
      },
      tableColumns: [
        {
          prop: 'field',
          label: 'Field',
          minWidth: 150
        },
        {
          prop: 'input',
          label: 'Value',
          minWidth: 275
        }
      ],
      order: {},
      tableData: [],
      searchedData: [],
    };
  },
  computed:{
    ...mapState([
      'toDetails'
    ]),
    ...mapGetters([
    ]),
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  methods: {
    ...mapMutations([
    ]),
    ...mapActions([
    ]),
    getOrders(){
      this.$http.get('odetails/'+this.toDetails)
        .then(response => response.json())
        .then(response => {
          this.order = response;
          this.tableData = response.details;
        })
        .catch(response => {
          if (response.status == 403)  this.$router.push('/logout');
      })
    }
  },
  mounted() {
    this.getOrders();
  }
};
</script>
<style>
.pagination-select {
  width: 200px;
}
</style>
